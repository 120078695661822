<template>
	<div class="container-fluid catalog-container-v1 p-0" v-if="isLoaded">
		<div class="row">
			<div class="col-12 pb-4">
				<img
					:src="config.bg_desktop !== null ? setImgFallback(config.bg_desktop) : setImgFallback(config.campaign_desktop_image)"
					alt=""
					class="img-fluid d-none d-md-block">
				<img
					:src="config.bg_mobile !== null ? setImgFallback(config.bg_mobile) : setImgFallback(config.campaign_mobile_image)"
					alt=""
					class="img-fluid d-block d-md-none">
			</div>
		</div>
		<div class="container"
			 v-if="config.catalog.back_link_active === 1 && (config.catalog.back_link_position === 'top'|| config.catalog.back_link_position === 'mixed')">
			<div class="row">
				<div class="col-12 pt-3 pb-2">
					<a class="back-link" :href="setUrlQuery()" rel="noopener"><i
						class="fas fa-arrow-left pr-2"></i>
						<span v-html="config.catalog.back_link_text"></span></a>
				</div>
			</div>
		</div>
		<div class="container position catalog mb-3">
			<div class="row">
				<div class="col-12 left-side"
					 :class="{'col-lg-8' : formData !== null && !formData.fat_form, 'col-md-12 col-lg-6' : formData !== null && formData.fat_form}">
					<!-- if price_position top -->
					<div class="row pb-4"
						 v-if="jsonConfig.catalog_settings !== undefined && jsonConfig.catalog_settings.price_top === false">
						<div class="col-12">
							<b-carousel id="carousel1"
										v-model="slide"
										style="text-shadow: 1px 1px 2px #333;"
										controls
										background="#efefef"
										:interval="4000"
										img-width="1024"
										img-height="480"
										@sliding-start="onSlideStart"
										@sliding-end="onSlideEnd">
								<b-carousel-slide v-for="image of sliderImages" v-bind:key="image">
									<img slot="img" class="d-block img-fluid w-100"
										 width="1024"
										 height="480"
										 v-lazy="setListingImg(image)" alt="image slot">
								</b-carousel-slide>
							</b-carousel>
						</div>
					</div>
					<div class="row mb-4 text-center text-md-left"
						 v-if="jsonConfig.catalog_settings !== undefined && jsonConfig.catalog_settings.head_text!==undefined && jsonConfig.catalog_settings.price_top === true">
						<div class="col-12">
							<span v-html="jsonConfig.catalog_settings.head_text"></span>
						</div>
					</div>
					<div
						v-if="jsonConfig.catalog_settings !== undefined && jsonConfig.catalog_settings.price_top === true">
						<div class="row pb-3">
							<div class="col-12">
								<div class="d-md-flex justify-content-md-between">
									<div class="d-md-flex align-content-md-start text-center text-md-left">
										<div>
											<h1>{{ carMake }} {{ carModel }}</h1>
											<h2>{{ carVersion }}</h2>
										</div>
									</div>
									<div class="price-zone-promo col-12 col-md-6 text-center pt-3 pb-3 pt-md-0 pb-md-0"
										 v-if="isPromoPrice === 1 && config.catalog.campaign_is_car_promo === 1">
										<div class="d-flex justify-content-end">
											<div class="left sizing">
												<p class="price-before-text">
													{{ config.catalog.price_text }}
												</p>
												<div class="price-before">
													{{
														carOldPrice | currency('€', 0, {
															thousandsSeparator : '.',
															symbolOnLeft       : false
														})
													}}
												</div>
											</div>
											<div class="sizing">
												<p class="after">
													{{ config.catalog.promo_price_text }}
												</p>
												<div class="price" v-if="carPrice !== 0">
													{{
														carPrice | currency('€', 0, {
															thousandsSeparator : '.', symbolOnLeft :
																false
														})
													}}
												</div>
												<div class="price" v-if="carPrice === 0"
													 style="font-size: 1.1rem;padding:6px;">
													Sob Consulta
												</div>
											</div>
										</div>
									</div>
									<div class="price-zone col-12 col-md-6 text-center pt-3 pb-3 pt-md-0 pb-md-0"
										 v-if="(isPromoPrice !== 1 && config.catalog.campaign_is_car_promo === 1) || config.catalog.campaign_is_car_promo !== 1">
										<div class="d-flex justify-content-md-end justify-content-center">
											<div class="sizing">
												<p class="after">
													Preço
												</p>
												<div class="price" v-if="carPrice !== 0">
													{{
														carOldPrice | currency('€', 0, {
															thousandsSeparator : '.',
															symbolOnLeft       : false
														})
													}}
												</div>
												<div class="price" v-if="carPrice === 0"
													 style="font-size: 1.1rem;padding:6px;">
													Sob Consulta
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- if price_position top end -->
					<div class="row pb-4"
						 v-if="jsonConfig.catalog_settings !== undefined && jsonConfig.catalog_settings.price_top === true">
						<div class="col-12">
							<b-carousel id="carousel1"
										v-model="slide"
										style="text-shadow: 1px 1px 2px #333;"
										controls
										background="#efefef"
										:interval="4000"
										img-width="1024"
										img-height="480"
										@sliding-start="onSlideStart"
										@sliding-end="onSlideEnd">
								<b-carousel-slide v-for="image of sliderImages" v-bind:key="image">
									<img slot="img" class="d-block img-fluid w-100"
										 width="1024"
										 height="480"
										 v-lazy="setListingImg(image)" alt="image slot">
								</b-carousel-slide>
							</b-carousel>
						</div>
					</div>
					<div class="row"
						 v-if="jsonConfig.catalog_settings !== undefined && jsonConfig.catalog_settings.cta_mobile !==null && jsonConfig.catalog_settings.cta_mobile !==undefined && jsonConfig.catalog_settings.price_top === true">
						<div class="col-12">
							<a href="#form" class="btn btn-primary btn-block d-md-none d-block mb-3">
								{{ jsonConfig.catalog_settings.cta_mobile }}
							</a>
						</div>
					</div>
					<!-- If price_position bottom -->
					<div
						v-if="jsonConfig.catalog_settings !== undefined && jsonConfig.catalog_settings.price_top === false">
						<div class="row mb-4 text-center text-md-left"
							 v-if="jsonConfig.catalog_settings !== undefined && jsonConfig.catalog_settings.head_text!==undefined ">
							<div class="col-12">
								<span v-html="jsonConfig.catalog_settings.head_text"></span>
							</div>
						</div>
						<div class="row pb-4">
							<div class="col-12">
								<div class="d-md-flex justify-content-md-between">
									<div class="d-md-flex align-content-md-start text-center text-md-left">
										<div>
											<h1>{{ carMake }} {{ carModel }}</h1>
											<h2>{{ carVersion }}</h2>
										</div>
									</div>
									<div class="price-zone-promo col-12 col-md-6  text-center pt-3 pb-3 pt-md-0 pb-md-0"
										 v-if="isPromoPrice === 1 && config.catalog.campaign_is_car_promo === 1">
										<div class="d-flex justify-content-end">
											<div class="left sizing">
												<p class="price-before-text">
													{{ config.catalog.price_text }}
												</p>
												<div class="price-before">
													{{
														carOldPrice | currency('€', 0, {
															thousandsSeparator : '.',
															symbolOnLeft       : false
														})
													}}
												</div>
											</div>
											<div class="sizing">
												<p class="after">
													{{ config.catalog.promo_price_text }}
												</p>
												<div class="price" v-if="carPrice !== 0">
													{{
														carPrice | currency('€', 0, {
															thousandsSeparator : '.', symbolOnLeft :
																false
														})
													}}
												</div>
												<div class="price" v-if="carPrice === 0"
													 style="font-size: 1.1rem;padding:6px;">
													Sob Consulta
												</div>
											</div>
										</div>
									</div>
									<div class="price-zone col-12 col-md-6 text-center pt-3 pb-3 pt-md-0 pb-md-0"
										 v-if="(isPromoPrice !== 1 && config.catalog.campaign_is_car_promo === 1) || config.catalog.campaign_is_car_promo !== 1">
										<div class="d-flex justify-content-md-end justify-content-center">
											<div class="sizing">
												<p class="after">
													Preço
												</p>
												<div class="price" v-if="carPrice !== 0">
													{{
														carOldPrice | currency('€', 0, {
															thousandsSeparator : '.',
															symbolOnLeft       : false
														})
													}}
												</div>
												<div class="price" v-if="carPrice === 0"
													 style="font-size: 1.1rem;padding:6px;">
													Sob Consulta
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row"
							 v-if="jsonConfig.catalog_settings !== undefined && jsonConfig.catalog_settings.cta_mobile !== undefined && jsonConfig.catalog_settings.cta_mobile !==null">
							<div class="col-12">
								<a href="#form" class="btn btn-primary btn-block d-md-none d-block mb-3">
									{{ jsonConfig.catalog_settings.cta_mobile }}
								</a>
							</div>
						</div>
					</div>
					<!-- If price_position bottom end-->
					<div class="row pb-4 pb-md-0 catalog-container-v2"
						 v-if="jsonConfig.catalog_settings !== undefined && jsonConfig.catalog_settings.list_desc === true">
						<div class="col-12 info-top">
							<div class="card">
								<div class="card-body">
									<div class="row">
										<div class="col-12">
											<ul v-if="isLoaded">
												<li class="d-flex justify-content-between">
													<div>ANO</div>
													<div>{{ carRegistrationDate }}</div>
												</li>
												<li class="d-flex justify-content-between">
													<div>Quilómetros</div>
													<div>{{ carCurrentKms }}</div>
												</li>
												<li class="d-flex justify-content-between"
													v-if="!toRemove.includes('car_type')">
													<div>Tipo</div>
													<div>{{ carType }}</div>
												</li>
												<li class="d-flex justify-content-between">
													<div>Combustível</div>
													<div> {{ carFuelType }}</div>
												</li>
												<li class="d-flex justify-content-between" v-if="carHorsePower !== 0">
													<div>Potência</div>
													<div>{{ carHorsePower }}</div>
												</li>
												<li class="d-flex justify-content-between" v-if="carEngineSize !== 0 && !toRemove.includes('enginesize')">
													<div>Cilindrada</div>
													<div>{{ carEngineSize }}</div>
												</li>
												<li class="d-flex justify-content-between"
													v-if="!toRemove.includes('color')">
													<div>Cor</div>
													<div>{{ carColor }}</div>
												</li>
												<li class="d-flex justify-content-between"
													v-if="!toRemove.includes('origin')">
													<div>Origem</div>
													<div>{{ carImport }}</div>
												</li>
												<li class="d-flex justify-content-between"
													v-if="!toRemove.includes('gear_box') && gearBox !== null">
													<div>Caixa</div>
													<div>{{ gearBox }}</div>
												</li>
												<li class="d-flex justify-content-between"
													v-if="carDoors !== 0 && carDoors !== null && !toRemove.includes('doors')">
													<div>Portas</div>
													<div>{{ carDoors }}</div>
												</li>
												<li class="d-flex justify-content-between"
													v-if="carSeats !== 0 && carSeats !== null && !toRemove.includes('seats')">
													<div>Lugares</div>
													<div>{{ carSeats }}</div>
												</li>
												<li class="d-flex justify-content-between"
													v-if="carPlate !== null && !toRemove.includes('plate')">
													<div>Matrícula</div>
													<div>{{ carPlate }}</div>
												</li>
												<li class="d-flex justify-content-between"
													v-if="carPlate !== null && !toRemove.includes('warranty')">
													<div>Garantia</div>
													<div style="font-size: 0.9rem; text-align:right"
														 v-html="warranty"></div>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row pb-4 pb-md-0"
						 v-if="jsonConfig.catalog_settings !== undefined && jsonConfig.catalog_settings.icon_desc === true">
						<div class="col-12">
							<div class="card">
								<div class="card-body">
									<div class="row stats">
										<div class="col-6 col-md-4">
											<img src="@/assets/images/ic_calendario.svg"
												 alt=""><span
											style="text-transform: capitalize">{{ carRegistrationDate }}</span>
										</div>
										<div class="col-6 col-md-4">
											<img src="@/assets/images/ic_combustivel.svg"
												 alt="">{{ carFuelType }}
										</div>
										<div class="col-6 col-md-4">
											<img src="@/assets/images/ic_cilindrada.svg"
												 alt="">{{ carEngineSize }}
										</div>
										<div class="col-6 col-md-4">
											<img src="@/assets/images/ic_viatura.svg"
												 class="cars"
												 alt="">{{ carType }}
										</div>
										<div class="col-6 col-md-4">
											<img src="@/assets/images/ic_quilometros.svg"
												 alt="">{{ carCurrentKms }}
										</div>
										<div class="col-6 col-md-4">
											<img src="@/assets/images/ic_Potencia.svg"
												 alt="">{{ carHorsePower }}
										</div>

										<div class="col-12">
											<i class="far fa-check-circle pr-1"
											   style="color: rgba(0,0,0,0.15);"></i> <span class="pr-2"
																						   style="color: rgba(0,0,0,0.5); font-size: 0.9rem;">Garantia</span>
											<span style="font-size: 0.9rem" v-html="warranty"></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 right-side" id="form"
					 v-if="formData !== null"
					 :class="{'col-md-12 col-lg-4' : !formData.fat_form, 'col-md-12 col-lg-6' : formData.fat_form}"
					 v-observe-visibility="visibilityChanged">
					<contact-form :config="config" :form-data="formData" :setup="setup"
								  :json-config="jsonConfig"></contact-form>
				</div>

				<div class="container"
					 v-if="config.catalog.back_link_active === 1 && (config.catalog.back_link_position === 'bottom'|| config.catalog.back_link_position === 'mixed')">
					<div class="row">
						<div class="col-12 mt-5 mb-4">
							<div class="d-flex justify-content-center">
								<a class="back-link" :href="setUrlQuery()" rel="noopener">
									<span v-html="config.catalog.back_link_text"></span></a>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 mt-3 mt-md-3 disclaimer mb-3 mb-md-3"
					 v-if="setup.disclaimer_zone === 'top'">
					<p id="privacy" v-html="privacyText"></p>
					<p id="contract" v-html="campaignContract">
					</p>
					<p id="disclaimer" v-html="setup.campaign_disclaimer"></p>
				</div>
			</div>
		</div>
		<div class="row d-md-none fixed-bottom sticky-cta text-center"
			 v-if="jsonConfig.catalog_settings !== undefined && jsonConfig.catalog_settings.fixed_nav === true">
			<div class="col-6 px-1">
				<a href="#form">
					<i class="fas fa-file-alt"></i><br>
					Pedir Proposta
				</a>
			</div>
			<div class="col-6 px-1">
				<a id="whats" :href="url" target="_blank" @click="triggerWhatsapp" rel="noopener">
					<i class="fab fa-whatsapp"></i><br>
					Contactar
				</a>
			</div>
		</div>
	</div>
</template>

<script>

import gtmEventCarDetailMixin      from '@/common/gtm-event-car-detail-mixin';
import pricesMixin                 from '@/common/prices-mixin';
import setImgFallbackMixin         from '@/common/set-img-fallback-mixin';
import ContactForm                 from '@/components/shared/ContactForm';
import {BCarousel, BCarouselSlide} from 'bootstrap-vue';
import * as moment                 from 'moment';

moment.locale('pt');

export default {
	name       : 'CatalogContainerV1Refresh',
	components : {
		ContactForm,
		BCarousel,
		BCarouselSlide
	},
	mixins     : [gtmEventCarDetailMixin, pricesMixin, setImgFallbackMixin],
	props      : {
		config           : {
			type     : Object,
			required : true,
		},
		formData         : {
			type     : Object,
			required : true,
		},
		setup            : {
			type     : Object,
			required : true,
		},
		privacyText      : {
			type     : String,
			required : true,
		},
		campaignContract : {
			type     : String,
			required : true,
		}
	},
	data() {
		return {
			url                 : String,
			response            : Object,
			isLoaded            : false,
			jsonConfig          : this.config.json !== null ? this.config.json.json_config : {},
			slide               : 0,
			sliding             : null,
			listingId           : String,
			carPrice            : String,
			carOldPrice         : String,
			carMake             : String,
			carModel            : String,
			carVersion          : String,
			carEngineSize       : String,
			carRegistrationDate : String,
			carCurrentKms       : String,
			carHorsePower       : String,
			carFuelType         : String,
			carType             : String,
			warranty            : String,
			sellerName          : String,
			sellerLocation      : String,
			sellerId            : Number,
			sellerAddress       : String,
			sellerCp            : String,
			sellerLogo          : String,
			sliderImages        : Array,
			uuid                : String,
			title               : String,
			description         : String,
			isPromoPrice        : Boolean,
			listing             : Object,
			carPlate            : String,
			carColor            : String,
			carImport           : String,
			carDoors            : String,
			carSeats            : String,
			gearBox             : String,
			toRemove            : Array,
		};
	},
	metaInfo() {
		return {
			title : this.title,
			meta  : [
				{
					name    : 'description',
					content : this.description,
				}
			]
		};
	},
	async mounted() {
		try {
			if (this.$route.params.listing_id !== undefined) {
				await this.$store.dispatch('getListingResponseData', {
					sellers   : [this.config.setup.seller_id.toString()].concat(this.config.catalog.seller_cars_merge !== null ? JSON.parse(this.config.catalog.seller_cars_merge) : []),
					listingId : this.$route.params.listing_id,
					catalogId : this.config.ad_engine_lps_catalogs_id
				});

				this.response    = this.$store.state.listingData.data;
				this.sellerName  = this.response.Seller.seller_has_seller_company[0]['seller_company'][0]['PublicName'] !== null ? this.response.Seller.seller_has_seller_company[0]['seller_company'][0]['PublicName'] : this.response.Seller.seller_has_seller_company[0]['seller_company'][0]['CompanyName'];
				this.title       = `${this.response.CarNexus.car_makes.MakeName === 'Skoda' ? 'ŠKODA' : this.response.CarNexus.car_makes.MakeName} ${this.response.CarNexus.ModelName} de ${moment(this.response.CarMainData.RegistrationDate, 'YYYY-MM-DD hh:mm:ss').format('YYYY')} - ${this.sellerName}`;
				this.description = `Descubra na ${this.sellerName} as Oportunidades em carros Usados e Seminovos ${this.response.CarNexus.car_makes.MakeName === 'Skoda' ? 'ŠKODA' : this.response.CarNexus.car_makes.MakeName} ${this.response.CarNexus.ModelName}.`;

				await this.$store.dispatch('getListingId', this.response.listing_id);
				await this.$store.dispatch('getListingLocationId', this.response.all_address_id);

				this.isLoaded = true;
				this.setData(this.response);
				this.$eventBus.$on('set-gtm', (data) => {
					if (data) {
						this.$gtm.trackEvent(this.trigger(this.response, this.$store.state.alid));
					}
				});
				window.alp.trackEvent({
					'event'          : 'ViewContent',
					'collected_data' : this.trigger(this.response, this.$store.state.alid)
				});

				let options = {
					make  : this.response.CarNexus.car_makes.MakeName === 'Skoda' ? 'ŠKODA' : this.response.CarNexus.car_makes.MakeName,
					model : this.response.CarNexus.ModelName,
					price : this.response.SalePrice,
					year  : moment(this.response.CarMainData.RegistrationDate, 'YYYY-MM-DD hh:mm:ss').format('YYYY'),
				};
				let contact = this.config.whatsapp_phone !== null ? this.config.whatsapp_phone : this.response.Address.seller_stand.whatsapp_contact;
				let message = this.config.whatsapp_message !== null ? this.setMessageVariables(this.config.whatsapp_message, options) : this.setText(options);
				this.url    = `https://wa.me/${contact}?text=${message}`;

				return;
			}

			window.location = window.location.href.split('viaturas')[0];
		} catch (error) {
			if (error.response.status === 500) {
				window.location = window.location.href.split('viaturas')[0];
			} else {
				window.location = error.response.data.message.redirect_url;
			}
		}
	},
	methods : {
		setMessageVariables(message, options) {
			let msg = message;
			if (message.includes('{make}')) {
				msg = msg.replace('{make}', options.make);
			}
			if (message.includes('{model}')) {
				msg = msg.replace('{model}', options.model);
			}
			if (message.includes('{price}')) {
				msg = msg.replace('{price}', `${options.price}€`);
			}
			if (message.includes('{year}')) {
				msg = msg.replace('{year}', options.year);
			}
			return msg;
		},
		setText(options) {
			let encodedURL = encodeURI(window.location.href);
			let text       = encodeURI(`Estou interessado no ${options.make} ${options.model} de ${options.year} por ${options.price}€. Podem dar-me mais informações?`);
			return `${text}%0A%0AURL%3A${encodedURL}`;
		},
		triggerWhatsapp() {
			this.$gtm.trackEvent({
				event          : 'Whatsapp_opened', // Event type [default = 'interaction'] (Optional)
				category       : 'Whatsapp_opened',
				action         : 'Whatsapp_opened',
				label          : 'Whatsapp_opened',
				eventID        : this.$store.state.alid,
				value          : 0,
				noninteraction : false // Optional
			});
			window.alp.trackEvent({ 'event' : 'Contact' });
		},
		setWarranty(data) {
			let warranty = '';
			if (data.WarrantySellerTime !== null) {
				warranty += data.WarrantySellerTime + ' meses, ';
			}
			if (data.WarrantySellerKms !== null) {
				warranty += data.WarrantySellerKms + ' Kms, ';
			}
			if (data.WarrantySellerDescription !== null) {
				warranty += data.WarrantySellerDescription + ', ';
			}
			if (data.WarrantyManufTime !== null) {
				warranty += data.WarrantyManufTime + ' meses, ';
			}
			if (data.WarrantyManufKms !== null) {
				warranty += data.WarrantyManufKms + ' Kms, ';
			}
			if (data.WarrantyManuDescription !== null) {
				warranty += data.WarrantyManuDescription + ', ';
			}

			if (warranty !== '') {
				return warranty.replace(/,(?=[^,]*$)/, '.');
			}
			return 'Sob Consulta';
		},
		setData(data) {
			let list                 = data;
			this.listingId           = list.listing_id;
			this.carMake             = list.CarNexus.car_makes.MakeName === 'Skoda' ? 'ŠKODA' : list.CarNexus.car_makes.MakeName;
			this.carModel            = list.CarNexus.ModelName;
			this.carVersion          = list.CarMainData.Version;
			this.carEngineSize       = `${list.CarMainData.EngineSize === -1 ? 0 : list.CarMainData.EngineSize} cm3`;
			this.carRegistrationDate = moment(list.CarMainData.RegistrationDate, 'YYYY-MM-DD hh:mm:ss').format('MMM YYYY');
			this.carCurrentKms       = `${list.CarMainData.CurrentKms} Km`;
			this.carHorsePower       = `${list.CarMainData.HorsePower} cv`;
			this.carFuelType         = list.CarMainData.fuel_type.FuelType;
			this.carType             = list.CarMainData.car_type.ModelType;
			this.sellerLocation      = list.Address.Locality;
			this.sellerId            = list.seller_id;
			this.sellerAddress       = list.Address.Address;
			this.sellerCp            = `${list.Address.CP4}-${list.Address.CP3}`;
			this.uuid                = list.uuid;
			this.sliderImages        = list.ItemImages.front !== undefined ? list.ItemImages.main.concat(list.ItemImages.front) : list.ItemImages.main;
			this.carPlate            = list.CarMainData.RegistrationPlate;
			this.carColor            = list.CarMainData.car_color.Color;
			this.carImport           = list.CarMainData.Import === 0 || list.CarMainData.Import === null ? 'Nacional' : 'Importado';
			this.carDoors            = list.CarMainData.Doors;
			this.carSeats            = list.CarMainData.Seats;
			this.sliderImages        = list.ItemImages.front !== undefined ? list.ItemImages.main.concat(list.ItemImages.front) : list.ItemImages.main;
			this.gearBox             = list.CarMainData.gear_box_type !== null ? list.CarMainData.gear_box_type.GearboxType : null;
			this.toRemove            = this.config.catalog.car_specs_remove !== null ? this.config.catalog.car_specs_remove : [];

			this.options  = {
				make  : this.carMake,
				model : this.carModel,
				price : this.carPrice,
				year  : this.carRegistrationDate,
			};
			this.warranty = this.setWarranty(list.CarMainData);

			let prices        = this.setPrices(this.config, list);
			this.isPromoPrice = prices.isPromoPrice;
			this.carPrice     = prices.carPrice;
			this.carOldPrice  = prices.carOldPrice;

			this.setLocation({
				StandName : list.Address.seller_stand.VisibleName !== null ? list.Address.seller_stand.VisibleName : list.Address.seller_stand.StandName,
				Address   : list.Address.Address,
				CP4       : list.Address.CP4,
				CP3       : list.Address.CP3,
				Locality  : list.Address.Locality,
				schedules : list.Address.seller_stand.schedules,
				PhoneOne  : list.Address.seller_stand.PhoneOne,
			});
		},
		setLocation(obj) {
			this.$eventBus.$emit('set-location', obj);
		},
		setUrlQuery() {
			let routeQuery = this.$route.query;
			let query      = {};

			for (let [k, v] of Object.entries(routeQuery)) {
				if (k !== 'debug' && k !== 'preview' && k !== 'teste') {
					query[k] = v;
				}
			}

			if (Object.entries(query).length > 0) {
				let serializeObj = Object.entries(query).map(([key, val]) => `${key}=${val}`).join('&');
				return `${this.$store.state.currentUrl}?${serializeObj}`;
			}

			return this.$store.state.currentUrl;
		},
		visibilityChanged(isVisible, entry) {
			this.isVisible = isVisible;
		},
		onSlideStart(slide) {
			this.sliding = true;
		},
		onSlideEnd(slide) {
			this.sliding = false;
		},
		setListingImg(image) {
			if (process.env.NODE_ENV === 'development') {
				return 'https://www.rotauto.com/assets/img/imagemOG_rotauto2020.jpg';
			}
			return `${process.env.VUE_APP_ROTAUTO_IMG_SOURCE}/${this.uuid}/${image}`;
		}
	}
};
</script>
