<template>
  <div class="container catalog-container-v2" v-if="isLoaded">
    <div class="row">
      <div class="col-12 campaign-top">
        <div class="row">
          <div class="col-12 pb-4">
            <a :href="setUrlQuery()" target="_blank" rel="noopener">
              <img
                :src="
                  config.bg_desktop !== null
                    ? setImgFallback(config.bg_desktop)
                    : setImgFallback(config.campaign_desktop_image)
                "
                alt=""
                style="margin: auto"
                class="img-fluid d-none d-lg-block"
              />
              <img
                :src="
                  config.bg_mobile !== null
                    ? setImgFallback(config.bg_mobile)
                    : setImgFallback(config.campaign_mobile_image)
                "
                alt=""
                class="img-fluid d-block d-lg-none"
              />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="container info-top pt-4 pb-4">
      <div class="row pb-3">
        <div
          class="col-12 col-lg-8 left-side d-md-none"
          v-if="
            jsonConfig.catalog_settings !== undefined &&
            jsonConfig.catalog_settings.price_top === false
          "
        >
          <div class="row pb-4">
            <div class="col-12">
              <b-carousel
                id="carousel1"
                v-model="slide"
                style="text-shadow: 1px 1px 2px #333"
                controls
                background="#efefef"
                :interval="4000"
                img-width="1024"
                img-height="480"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <b-carousel-slide
                  v-for="(image, indx) of sliderImages"
                  :key="indx"
                  :index="indx"
                >
                  <img
                    slot="img"
                    class="d-block img-fluid w-100"
                    width="1024"
                    height="480"
                    v-lazy="setListingImg(image)"
                    :alt="`Imagem carro ${indx}`"
                  />
                </b-carousel-slide>
              </b-carousel>
            </div>
          </div>

          <!--					TODO: stickybutton-->
          <!--					<div class="row d-block d-md-none" v-if="config.form.top_button_catalog_text">-->
          <!--						<div class="col-12">-->
          <!--							<a class="btn btn-primary mt-2 btn-block" href="#form"-->
          <!--							   style="text-decoration: none;margin-bottom: 30px;">-->
          <!--								<span>{{ config.form.form_button }}</span>-->
          <!--							</a>-->
          <!--						</div>-->
          <!--					</div>-->
        </div>
        <div class="col-12">
          <div
            class="row mb-4 text-md-left text-center"
            v-if="
              jsonConfig.catalog_settings !== undefined &&
              jsonConfig.catalog_settings.head_text !== undefined
            "
          >
            <div class="col-12">
              <span v-html="jsonConfig.catalog_settings.head_text"></span>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
              <div class="text-center text-md-left">
                <h1>{{ carMake }} {{ carModel }}</h1>
                <h2>{{ carVersion }}</h2>
              </div>
            </div>
            <div
              class="price-zone-promo col-12 col-md-6 col-lg-4 text-center pt-3 pb-3 pt-md-0 pb-md-0"
              v-if="
                isPromoPrice === 1 && config.catalog.campaign_is_car_promo === 1
              "
            >
              <div class="d-flex justify-content-end">
                <div class="left sizing">
                  <p class="price-before-text">
                    {{ config.catalog.price_text }}
                  </p>
                  <div class="price-before">
                    {{
                      carOldPrice
                        | currency("€", 0, {
                          thousandsSeparator: ".",
                          symbolOnLeft: false,
                        })
                    }}<span
                      v-if="config.catalog.show_tax_tag === 1"
                      style="font-size: 0.8rem"
                      >+IVA</span
                    >
                  </div>
                </div>
                <div class="sizing">
                  <p class="after">
                    {{ config.catalog.promo_price_text }}
                  </p>
                  <div class="price" v-if="carPrice !== 0">
                    {{
                      carPrice
                        | currency("€", 0, {
                          thousandsSeparator: ".",
                          symbolOnLeft: false,
                        })
                    }}<span
                      v-if="config.catalog.show_tax_tag === 1"
                      style="font-size: 0.8rem"
                      >+IVA</span
                    >
                  </div>
                  <div
                    class="price"
                    v-if="carPrice === 0"
                    style="font-size: 1.1rem; padding: 6px"
                  >
                    Sob Consulta
                  </div>
                </div>
              </div>
            </div>
            <div
              class="price-zone col-12 col-md-6 col-lg-4 text-center pt-3 pb-3 pt-md-0 pb-md-0"
              v-if="
                (isPromoPrice !== 1 &&
                  config.catalog.campaign_is_car_promo === 1) ||
                config.catalog.campaign_is_car_promo !== 1
              "
            >
              <div class="d-flex justify-content-md-end justify-content-center">
                <div class="sizing">
                  <p class="after">Preço</p>
                  <div class="price" v-if="carPrice !== 0">
                    {{
                      carOldPrice
                        | currency("€", 0, {
                          thousandsSeparator: ".",
                          symbolOnLeft: false,
                        })
                    }}<span
                      v-if="config.catalog.show_tax_tag === 1"
                      style="font-size: 0.8rem"
                      >+IVA</span
                    >
                  </div>
                  <div
                    class="price"
                    v-if="carPrice === 0"
                    style="font-size: 1.1rem; padding: 6px"
                  >
                    Sob Consulta
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-2 d-md-block d-none">
              <div class="text-center text-lg-left">
                <a href="#form">
                  <button class="btn btn-secondary btn-block btn-extra">
                    <i class="fas fa-file-alt"></i> Pedir Proposta
                  </button>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-2 pl-0 d-md-block d-none">
              <div class="text-center text-lg-left">
                <a href="#form">
                  <button class="btn btn-secondary btn-block btn-extra">
                    <span class="icon-test-drive"></span> Marcar Test Drive
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-12 col-lg-8 left-side"
          v-if="
            jsonConfig.catalog_settings !== undefined &&
            jsonConfig.catalog_settings.price_top === true
          "
        >
          <div class="row pb-4">
            <div class="col-12">
              <b-carousel
                id="carousel1"
                v-model="slide"
                style="text-shadow: 1px 1px 2px #333"
                controls
                background="#efefef"
                :interval="4000"
                img-width="1024"
                img-height="480"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <b-carousel-slide
                  v-for="(image, indx) of sliderImages"
                  :key="indx"
                  :index="indx"
                >
                  <img
                    slot="img"
                    class="d-block img-fluid w-100"
                    width="1024"
                    height="480"
                    v-lazy="setListingImg(image)"
                    :alt="`Imagem carro ${indx}`"
                  />
                </b-carousel-slide>
              </b-carousel>
            </div>
          </div>

          <!--					TODO: stickybutton-->
          <!--					<div class="row d-block d-md-none" v-if="config.form.top_button_catalog_text">-->
          <!--						<div class="col-12">-->
          <!--							<a class="btn btn-primary mt-2 btn-block" href="#form"-->
          <!--							   style="text-decoration: none;margin-bottom: 30px;">-->
          <!--								<span>{{ config.form.form_button }}</span>-->
          <!--							</a>-->
          <!--						</div>-->
          <!--					</div>-->
        </div>
        <div class="col-12 col-lg-4">
          <div class="row pb-4">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <ul v-if="isLoaded">
                        <li
                          v-if="
                            listing.seller_id === 415 ||
                            listing.seller_id === 627
                          "
                          class="d-flex justify-content-between"
                        >
                          <div>LOCALIZAÇÃO</div>
                          <div>
                            {{
                              sellerLocation === "Santo António da Charneca"
                                ? "Barreiro"
                                : sellerLocation
                            }}
                          </div>
                        </li>
                        <li class="d-flex justify-content-between">
                          <div>ANO</div>
                          <div>{{ carRegistrationDate }}</div>
                        </li>
                        <li class="d-flex justify-content-between">
                          <div>Quilómetros</div>
                          <div>{{ carCurrentKms }}</div>
                        </li>
                        <li
                          class="d-flex justify-content-between"
                          v-if="!toRemove.includes('car_type')"
                        >
                          <div>Tipo</div>
                          <div>{{ carType }}</div>
                        </li>
                        <li class="d-flex justify-content-between">
                          <div>Combustível</div>
                          <div>{{ carFuelType }}</div>
                        </li>
                        <li
                          class="d-flex justify-content-between"
                          v-if="carHorsePower !== 0"
                        >
                          <div>Potência</div>
                          <div>{{ carHorsePower }}</div>
                        </li>
                        <li
                          class="d-flex justify-content-between"
                          v-if="carEngineSize !== 0 && !toRemove.includes('enginesize')"
                        >
                          <div>Cilindrada</div>
                          <div>{{ carEngineSize }}</div>
                        </li>
                        <li
                          class="d-flex justify-content-between"
                          v-if="!toRemove.includes('color')"
                        >
                          <div>Cor</div>
                          <div>{{ carColor }}</div>
                        </li>
                        <li
                          class="d-flex justify-content-between"
                          v-if="!toRemove.includes('origin')"
                        >
                          <div>Origem</div>
                          <div>{{ carImport }}</div>
                        </li>
                        <li
                          class="d-flex justify-content-between"
                          v-if="
                            !toRemove.includes('gear_box') && gearBox !== null
                          "
                        >
                          <div>Caixa</div>
                          <div>{{ gearBox }}</div>
                        </li>
                        <li
                          class="d-flex justify-content-between"
                          v-if="
                            carDoors !== 0 &&
                            carDoors !== null &&
                            !toRemove.includes('doors')
                          "
                        >
                          <div>Portas</div>
                          <div>{{ carDoors }}</div>
                        </li>
                        <li
                          class="d-flex justify-content-between"
                          v-if="
                            carSeats !== 0 &&
                            carSeats !== null &&
                            !toRemove.includes('seats')
                          "
                        >
                          <div>Lugares</div>
                          <div>{{ carSeats }}</div>
                        </li>
                        <li
                          class="d-flex justify-content-between"
                          v-if="
                            carPlate !== null && !toRemove.includes('plate')
                          "
                        >
                          <div>Matrícula</div>
                          <div>{{ carPlate }}</div>
                        </li>
                        <li
                          class="d-flex justify-content-between"
                          v-if="
                            carPlate !== null && !toRemove.includes('warranty')
                          "
                        >
                          <div>Garantia</div>
                          <div
                            style="font-size: 0.9rem; text-align: right"
                            v-html="warranty"
                          ></div>
                        </li>
                        <li
                          class="d-flex justify-content-between"
                          v-if="
                            carPlate !== null && !toRemove.includes('warranty')
                          "
                        >
                          <div
                            style="
                              font-weight: 400;
                              text-transform: none;
                              font-size: 0.7rem;
                            "
                            v-if="parseInt(warranty) < 36"
                          >
                            Duração da garantia, por acordo, nos termos do nº 3
                            do art.º 12.º do DL nº 84/2021, de 18 de outubro.
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row d-md-none fixed-bottom sticky-cta text-center">
        <div class="col-4 px-1">
          <a href="#form">
            <i class="fas fa-file-alt"></i><br />
            Pedir Proposta
          </a>
        </div>
        <div class="col-4 px-1">
          <a href="#form">
            <span class="icon-test-drive sticky-icon"></span><br />
            Marcar Test Drive
          </a>
        </div>
        <div class="col-4 px-1">
          <a
            id="whats"
            :href="url"
            target="_blank"
            @click="triggerWhatsapp"
            rel="noopener"
          >
            <i class="fab fa-whatsapp"></i><br />
            Contactar
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gtmEventCarDetailMixin from "@/common/gtm-event-car-detail-mixin";
import pricesMixin from "@/common/prices-mixin";
import setImgFallbackMixin from "@/common/set-img-fallback-mixin";
import { BCarousel, BCarouselSlide } from "bootstrap-vue";
import * as moment from "moment";

moment.locale("pt");

export default {
  name: "CatalogContainerV2Refresh",
  components: {
    BCarousel,
    BCarouselSlide,
  },
  mixins: [gtmEventCarDetailMixin, pricesMixin, setImgFallbackMixin],
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
    setup: {
      type: Object,
      required: true,
    },
    privacyText: {
      type: String,
      required: true,
    },
    campaignContract: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      url: String,
      response: Object,
      isLoaded: false,
      jsonConfig: this.config.json !== null ? this.config.json.json_config : {},
      slide: 0,
      sliding: null,
      carPrice: String,
      carOldPrice: String,
      carMake: String,
      carModel: String,
      carVersion: String,
      carEngineSize: String,
      carRegistrationDate: String,
      carCurrentKms: String,
      carHorsePower: String,
      carFuelType: String,
      carType: String,
      warranty: String,
      sellerName: String,
      sellerLocation: String,
      sellerId: Number,
      sellerAddress: String,
      sellerCp: String,
      sellerLogo: String,
      sliderImages: Array,
      uuid: String,
      listing: Object,
      carPlate: String,
      carColor: String,
      carImport: String,
      carDoors: String,
      carSeats: String,
      gearBox: String,
      title: String,
      description: String,
      isPromoPrice: Boolean,
      toRemove: Array,
    };
  },
  created() {},
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: this.description,
        },
      ],
    };
  },
  async mounted() {
    try {
      if (this.$route.params.listing_id !== undefined) {
        await this.$store.dispatch("getListingResponseData", {
          sellers: [this.config.setup.seller_id.toString()].concat(
            this.config.catalog.seller_cars_merge !== null
              ? JSON.parse(this.config.catalog.seller_cars_merge)
              : []
          ),
          listingId: this.$route.params.listing_id,
          catalogId: this.config.ad_engine_lps_catalogs_id,
        });

        this.response = this.$store.state.listingData.data;
        this.sellerName =
          this.response.Seller.seller_has_seller_company[0][
            "seller_company"
          ][0]["PublicName"] !== null
            ? this.response.Seller.seller_has_seller_company[0][
                "seller_company"
              ][0]["PublicName"]
            : this.response.Seller.seller_has_seller_company[0][
                "seller_company"
              ][0]["CompanyName"];
        this.title = `${
          this.response.CarNexus.car_makes.MakeName === "Skoda"
            ? "ŠKODA"
            : this.response.CarNexus.car_makes.MakeName
        } ${this.response.CarNexus.ModelName} de ${moment(
          this.response.CarMainData.RegistrationDate,
          "YYYY-MM-DD hh:mm:ss"
        ).format("YYYY")} - ${this.sellerName}`;
        this.description = `Descubra na ${
          this.sellerName
        } as Oportunidades em carros Usados e Seminovos ${
          this.response.CarNexus.car_makes.MakeName === "Skoda"
            ? "ŠKODA"
            : this.response.CarNexus.car_makes.MakeName
        } ${this.response.CarNexus.ModelName}.`;

        await this.$store.dispatch("getListingId", this.response.listing_id);
        await this.$store.dispatch(
          "getListingLocationId",
          this.response.all_address_id
        );

        this.isLoaded = true;
        this.setData(this.response);
        this.$eventBus.$on("set-gtm", (data) => {
          if (data) {
            this.$gtm.trackEvent(
              this.trigger(this.response, this.$store.state.alid)
            );
          }
        });

        window.alp.trackEvent({
          event: "ViewContent",
          collected_data: this.trigger(this.response, this.$store.state.alid),
        });

        let options = {
          make:
            this.response.CarNexus.car_makes.MakeName === "Skoda"
              ? "ŠKODA"
              : this.response.CarNexus.car_makes.MakeName,
          model: this.response.CarNexus.ModelName,
          price: this.response.SalePrice,
          year: moment(
            this.response.CarMainData.RegistrationDate,
            "YYYY-MM-DD hh:mm:ss"
          ).format("YYYY"),
        };
        let contact =
          this.config.whatsapp_phone !== null
            ? this.config.whatsapp_phone
            : this.response.Address.seller_stand.whatsapp_contact;
        let message =
          this.config.whatsapp_message !== null
            ? this.setMessageVariables(this.config.whatsapp_message, options)
            : this.setText(options);
        this.url = `https://wa.me/${contact}?text=${message}`;

        return;
      }

      window.location = window.location.href.split("viaturas")[0];
    } catch (error) {
      if (error.response.status === 500) {
        window.location = window.location.href.split("viaturas")[0];
      } else {
        window.location = error.response.data.message.redirect_url;
      }
    }
  },
  methods: {
    setMessageVariables(message, options) {
      let msg = message;
      if (message.includes("{make}")) {
        msg = msg.replace("{make}", options.make);
      }
      if (message.includes("{model}")) {
        msg = msg.replace("{model}", options.model);
      }
      if (message.includes("{price}")) {
        msg = msg.replace("{price}", `${options.price}€`);
      }
      if (message.includes("{year}")) {
        msg = msg.replace("{year}", options.year);
      }
      return msg;
    },
    setText(options) {
      let encodedURL = encodeURI(window.location.href);
      let text = encodeURI(
        `Estou interessado no ${options.make} ${options.model} de ${options.year} por ${options.price}€. Podem dar-me mais informações?`
      );
      return `${text}%0A%0AURL%3A${encodedURL}`;
    },
    triggerWhatsapp() {
      this.$gtm.trackEvent({
        event: "Whatsapp_opened", // Event type [default = 'interaction'] (Optional)
        category: "Whatsapp_opened",
        action: "Whatsapp_opened",
        label: "Whatsapp_opened",
        eventID: this.$store.state.alid,
        value: 0,
        noninteraction: false, // Optional
      });
      window.alp.trackEvent({ event: "Contact" });
    },
    setWarranty(data) {
      let warranty = "";
      if (data.WarrantySellerTime !== null) {
        warranty += data.WarrantySellerTime + " meses, ";
      }
      if (data.WarrantySellerKms !== null) {
        warranty += data.WarrantySellerKms + " Kms, ";
      }
      if (data.WarrantySellerDescription !== null) {
        warranty += data.WarrantySellerDescription + ", ";
      }
      if (data.WarrantyManufTime !== null) {
        warranty += data.WarrantyManufTime + " meses, ";
      }
      if (data.WarrantyManufKms !== null) {
        warranty += data.WarrantyManufKms + " Kms, ";
      }
      if (data.WarrantyManuDescription !== null) {
        warranty += data.WarrantyManuDescription + ", ";
      }

      if (warranty !== "") {
        return warranty.replace(/,(?=[^,]*$)/, ".");
      }
      return "Sob Consulta";
    },
    setData(data) {
      let list = data;
      this.listing = list;
      this.carMake =
        list.CarNexus.car_makes.MakeName === "Skoda"
          ? "ŠKODA"
          : list.CarNexus.car_makes.MakeName;
      this.carModel = list.CarNexus.ModelName;
      this.carVersion = list.CarMainData.Version;
      this.carEngineSize =
        list.CarMainData.EngineSize > 1
          ? `${list.CarMainData.EngineSize} cm3`
          : 0;
      this.carRegistrationDate = moment(
        list.CarMainData.RegistrationDate,
        "YYYY-MM-DD hh:mm:ss"
      ).format("MMM YYYY");
      this.carCurrentKms = `${list.CarMainData.CurrentKms} Km`;
      this.carHorsePower =
        list.CarMainData.HorsePower > 1
          ? `${list.CarMainData.HorsePower} cv`
          : 0;
      this.carFuelType = list.CarMainData.fuel_type.FuelType;
      this.carType = list.CarMainData.car_type.ModelType;
      this.gearBox =
        list.CarMainData.gear_box_type !== null
          ? list.CarMainData.gear_box_type.GearboxType
          : null;
      this.warranty = this.setWarranty(list.CarMainData);
      this.sellerLocation = list.Address.Locality;
      this.sellerId = list.seller_id;
      this.sellerAddress = list.Address.Address;
      this.sellerCp = `${list.Address.CP4}-${list.Address.CP3}`;
      this.uuid = list.uuid;
      this.carPlate = list.CarMainData.RegistrationPlate;
      this.carColor = list.CarMainData.car_color.Color;
      this.carImport =
        list.CarMainData.Import === 0 || list.CarMainData.Import === null
          ? "Nacional"
          : "Importado";
      this.carDoors = list.CarMainData.Doors;
      this.carSeats = list.CarMainData.Seats;
      this.sliderImages =
        list.ItemImages.front !== undefined
          ? list.ItemImages.main.concat(list.ItemImages.front)
          : list.ItemImages.main;
      this.toRemove =
        this.config.catalog.car_specs_remove !== null
          ? this.config.catalog.car_specs_remove
          : [];
      this.options = {
        make: this.carMake,
        model: this.carModel,
        price: this.carPrice,
        year: this.carRegistrationDate,
      };

      let prices = this.setPrices(this.config, list);
      this.isPromoPrice = prices.isPromoPrice;
      this.carPrice = prices.carPrice;
      this.carOldPrice = prices.carOldPrice;

      this.setLocation({
        StandName:
          list.Address.seller_stand.VisibleName !== null
            ? list.Address.seller_stand.VisibleName
            : list.Address.seller_stand.StandName,
        Address: list.Address.Address,
        CP4: list.Address.CP4,
        CP3: list.Address.CP3,
        Locality: list.Address.Locality,
        schedules: list.Address.seller_stand.schedules,
        PhoneOne: list.Address.seller_stand.PhoneOne,
      });
    },
    setLocation(obj) {
      this.$eventBus.$emit("set-location", obj);
    },
    setUrlQuery() {
      let routeQuery = this.$route.query;
      let query = {};

      for (let [k, v] of Object.entries(routeQuery)) {
        if (k !== "debug" && k !== "preview" && k !== "teste") {
          query[k] = v;
        }
      }

      if (Object.entries(query).length > 0) {
        let serializeObj = Object.entries(query)
          .map(([key, val]) => `${key}=${val}`)
          .join("&");
        return `${this.$store.state.currentUrl}?${serializeObj}`;
      }

      return this.$store.state.currentUrl;
    },
    visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible;
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    setListingImg(image) {
      if (process.env.NODE_ENV === "development") {
        return "https://www.rotauto.com/assets/img/imagemOG_rotauto2020.jpg";
      }
      return `${process.env.VUE_APP_ROTAUTO_IMG_SOURCE}/${this.uuid}/${image}`;
    },
  },
};
</script>
