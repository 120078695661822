import moment from 'moment';

const gtmEventCarDetailMixin = {
	methods : {
		trigger(carData, alid) {
			return {
				value            : carData.SalePrice !== undefined ? parseInt(carData.SalePrice) : 0,
				currency         : 'EUR',
				content_ids      : [carData.id],
				content_type     : 'vehicle',
				content_name     : `${carData.CarNexus.car_makes.MakeName === 'Skoda' ? 'ŠKODA' : carData.CarNexus.car_makes.MakeName} ${carData.CarNexus.ModelName} ${carData.CarMainData.Version !== null ? carData.CarMainData.Version : ''}`,
				content_category : 'detailpage',
				event            : 'detailpage',
				eventID          : alid,
				contents         : [{
					id       : carData.car_nexus_id,
					quantity : 1,
					Make     : carData.CarNexus.car_makes.MakeName === 'Skoda' ? 'ŠKODA' : carData.CarNexus.car_makes.MakeName,
					Model    : carData.CarNexus.ModelName,
				}],
				vehicletype      : 'Usado',
				vehiclecategory  : carData.CarMainData.car_type.ModelType, // *** custom property
				usertype         : null, // *** custom property
				lifestyle        : null, // *** custom property
				make             : carData.CarNexus.car_makes.MakeName === 'Skoda' ? 'ŠKODA' : carData.CarNexus.car_makes.MakeName, // custom property
				model            : carData.CarNexus.ModelName, // custom property
				year             : carData.CarMainData.RegistrationDate !== undefined ?
					parseInt(moment(carData.CarMainData.RegistrationDate, 'YYYY-MM-DD').format('Y')) : '', // custom
				fuel             : this.parseFuel(carData.CarMainData.fuel_type_id), // custom property
				price            : carData.SalePrice !== undefined ? parseInt(carData.SalePrice) : 0,// custom
				standname        : `${carData.seller_id}_${carData.Address.seller_stand.StandName.replace(' ', '_')}`, // custom property,);
				postal_code      : carData.Address.CP4 !== undefined ? `${carData.Address.CP4}-${carData.Address.CP3}` : '',
				country          : 'Portugal', // RECOMMENDED  don't use abbreviations
				state_of_vehicle : carData.CarMainData.car_state_id === 1 ? 'NEW' : (carData.CarMainData.car_state_id === 2 ? 'CPO' : 'USED'),
				exterior_color   : carData.CarMainData.car_color.Color, // RECOMMENDED
				transmission     : this.parseTransmission(carData.CarMainData.gearbox_type_id),
				drivetrain       : this.parseDrivetrain(carData.CarMainData.drivetrain_type_id), // RECOMMDENDED
				body_style       : this.parseBody(carData.CarMainData.car_type_id), // RECOMMENDED
			};
		},
		parseTransmission(val) {
			if (val === 1) {
				return 'MANUAL';
			}

			if (val === 3) {
				return 'AUTOMATIC';
			}

			return 'Other';
		},
		parseFuel(val) {
			if (val === 5
				|| val === 6
				|| val === 7
				|| val === 8) {
				return 'HYBRID';
			} else if (val === 1) {
				return 'GASOLINE';
			} else if (val === 2) {
				return 'DIESEL';
			} else if (val === 3) {
				return 'FLEX';
			} else if (val === 4) {
				return 'ELECTRIC';
			} else {
				return 'OTHER';
			}
		},
		parseBody(val) {
			if (val === 4) {
				return 'WAGON';
			} else if (val === 3) {
				return 'SEDAN';
			} else if (val === 5) {
				return 'SUV';
			} else if (val === 6) {
				return 'HATCHBACK';
			} else if (val === 7) {
				return 'CONVERTIBLE';
			} else if (val === 11 || val === 10) {
				return 'TRUCK';
			} else if (val === 9) {
				return 'COUPE';
			} else {
				return 'OTHER';
			}
		},
		parseDrivetrain(val) {
			if (val === 1) {
				return 'FWD';
			} else if (val === 2) {
				return 'RWD';
			} else if (val === 3) {
				return 'AWD';
			} else {
				return 'Other';
			}
		}
	}
};

export default gtmEventCarDetailMixin;
