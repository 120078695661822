var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLoaded)?_c('div',{staticClass:"container catalog-container-v2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 campaign-top"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pb-4"},[_c('a',{attrs:{"href":_vm.setUrlQuery(),"target":"_blank","rel":"noopener"}},[_c('img',{staticClass:"img-fluid d-none d-lg-block",staticStyle:{"margin":"auto"},attrs:{"src":_vm.config.bg_desktop !== null
                  ? _vm.setImgFallback(_vm.config.bg_desktop)
                  : _vm.setImgFallback(_vm.config.campaign_desktop_image),"alt":""}}),_c('img',{staticClass:"img-fluid d-block d-lg-none",attrs:{"src":_vm.config.bg_mobile !== null
                  ? _vm.setImgFallback(_vm.config.bg_mobile)
                  : _vm.setImgFallback(_vm.config.campaign_mobile_image),"alt":""}})])])])])]),_c('div',{staticClass:"container info-top pt-4 pb-4"},[_c('div',{staticClass:"row pb-3"},[(
          _vm.jsonConfig.catalog_settings !== undefined &&
          _vm.jsonConfig.catalog_settings.price_top === false
        )?_c('div',{staticClass:"col-12 col-lg-8 left-side d-md-none"},[_c('div',{staticClass:"row pb-4"},[_c('div',{staticClass:"col-12"},[_c('b-carousel',{staticStyle:{"text-shadow":"1px 1px 2px #333"},attrs:{"id":"carousel1","controls":"","background":"#efefef","interval":4000,"img-width":"1024","img-height":"480"},on:{"sliding-start":_vm.onSlideStart,"sliding-end":_vm.onSlideEnd},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.sliderImages),function(image,indx){return _c('b-carousel-slide',{key:indx,attrs:{"index":indx}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.setListingImg(image)),expression:"setListingImg(image)"}],staticClass:"d-block img-fluid w-100",attrs:{"slot":"img","width":"1024","height":"480","alt":`Imagem carro ${indx}`},slot:"img"})])}),1)],1)])]):_vm._e(),_c('div',{staticClass:"col-12"},[(
            _vm.jsonConfig.catalog_settings !== undefined &&
            _vm.jsonConfig.catalog_settings.head_text !== undefined
          )?_c('div',{staticClass:"row mb-4 text-md-left text-center"},[_c('div',{staticClass:"col-12"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.jsonConfig.catalog_settings.head_text)}})])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-4"},[_c('div',{staticClass:"text-center text-md-left"},[_c('h1',[_vm._v(_vm._s(_vm.carMake)+" "+_vm._s(_vm.carModel))]),_c('h2',[_vm._v(_vm._s(_vm.carVersion))])])]),(
              _vm.isPromoPrice === 1 && _vm.config.catalog.campaign_is_car_promo === 1
            )?_c('div',{staticClass:"price-zone-promo col-12 col-md-6 col-lg-4 text-center pt-3 pb-3 pt-md-0 pb-md-0"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',{staticClass:"left sizing"},[_c('p',{staticClass:"price-before-text"},[_vm._v(" "+_vm._s(_vm.config.catalog.price_text)+" ")]),_c('div',{staticClass:"price-before"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.carOldPrice,"€", 0, { thousandsSeparator: ".", symbolOnLeft: false, }))),(_vm.config.catalog.show_tax_tag === 1)?_c('span',{staticStyle:{"font-size":"0.8rem"}},[_vm._v("+IVA")]):_vm._e()])]),_c('div',{staticClass:"sizing"},[_c('p',{staticClass:"after"},[_vm._v(" "+_vm._s(_vm.config.catalog.promo_price_text)+" ")]),(_vm.carPrice !== 0)?_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.carPrice,"€", 0, { thousandsSeparator: ".", symbolOnLeft: false, }))),(_vm.config.catalog.show_tax_tag === 1)?_c('span',{staticStyle:{"font-size":"0.8rem"}},[_vm._v("+IVA")]):_vm._e()]):_vm._e(),(_vm.carPrice === 0)?_c('div',{staticClass:"price",staticStyle:{"font-size":"1.1rem","padding":"6px"}},[_vm._v(" Sob Consulta ")]):_vm._e()])])]):_vm._e(),(
              (_vm.isPromoPrice !== 1 &&
                _vm.config.catalog.campaign_is_car_promo === 1) ||
              _vm.config.catalog.campaign_is_car_promo !== 1
            )?_c('div',{staticClass:"price-zone col-12 col-md-6 col-lg-4 text-center pt-3 pb-3 pt-md-0 pb-md-0"},[_c('div',{staticClass:"d-flex justify-content-md-end justify-content-center"},[_c('div',{staticClass:"sizing"},[_c('p',{staticClass:"after"},[_vm._v("Preço")]),(_vm.carPrice !== 0)?_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.carOldPrice,"€", 0, { thousandsSeparator: ".", symbolOnLeft: false, }))),(_vm.config.catalog.show_tax_tag === 1)?_c('span',{staticStyle:{"font-size":"0.8rem"}},[_vm._v("+IVA")]):_vm._e()]):_vm._e(),(_vm.carPrice === 0)?_c('div',{staticClass:"price",staticStyle:{"font-size":"1.1rem","padding":"6px"}},[_vm._v(" Sob Consulta ")]):_vm._e()])])]):_vm._e(),_vm._m(0),_vm._m(1)])])]),_c('div',{staticClass:"row"},[(
          _vm.jsonConfig.catalog_settings !== undefined &&
          _vm.jsonConfig.catalog_settings.price_top === true
        )?_c('div',{staticClass:"col-12 col-lg-8 left-side"},[_c('div',{staticClass:"row pb-4"},[_c('div',{staticClass:"col-12"},[_c('b-carousel',{staticStyle:{"text-shadow":"1px 1px 2px #333"},attrs:{"id":"carousel1","controls":"","background":"#efefef","interval":4000,"img-width":"1024","img-height":"480"},on:{"sliding-start":_vm.onSlideStart,"sliding-end":_vm.onSlideEnd},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.sliderImages),function(image,indx){return _c('b-carousel-slide',{key:indx,attrs:{"index":indx}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.setListingImg(image)),expression:"setListingImg(image)"}],staticClass:"d-block img-fluid w-100",attrs:{"slot":"img","width":"1024","height":"480","alt":`Imagem carro ${indx}`},slot:"img"})])}),1)],1)])]):_vm._e(),_c('div',{staticClass:"col-12 col-lg-4"},[_c('div',{staticClass:"row pb-4"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.isLoaded)?_c('ul',[(
                          _vm.listing.seller_id === 415 ||
                          _vm.listing.seller_id === 627
                        )?_c('li',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("LOCALIZAÇÃO")]),_c('div',[_vm._v(" "+_vm._s(_vm.sellerLocation === "Santo António da Charneca" ? "Barreiro" : _vm.sellerLocation)+" ")])]):_vm._e(),_c('li',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("ANO")]),_c('div',[_vm._v(_vm._s(_vm.carRegistrationDate))])]),_c('li',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Quilómetros")]),_c('div',[_vm._v(_vm._s(_vm.carCurrentKms))])]),(!_vm.toRemove.includes('car_type'))?_c('li',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Tipo")]),_c('div',[_vm._v(_vm._s(_vm.carType))])]):_vm._e(),_c('li',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Combustível")]),_c('div',[_vm._v(_vm._s(_vm.carFuelType))])]),(_vm.carHorsePower !== 0)?_c('li',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Potência")]),_c('div',[_vm._v(_vm._s(_vm.carHorsePower))])]):_vm._e(),(_vm.carEngineSize !== 0 && !_vm.toRemove.includes('enginesize'))?_c('li',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Cilindrada")]),_c('div',[_vm._v(_vm._s(_vm.carEngineSize))])]):_vm._e(),(!_vm.toRemove.includes('color'))?_c('li',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Cor")]),_c('div',[_vm._v(_vm._s(_vm.carColor))])]):_vm._e(),(!_vm.toRemove.includes('origin'))?_c('li',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Origem")]),_c('div',[_vm._v(_vm._s(_vm.carImport))])]):_vm._e(),(
                          !_vm.toRemove.includes('gear_box') && _vm.gearBox !== null
                        )?_c('li',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Caixa")]),_c('div',[_vm._v(_vm._s(_vm.gearBox))])]):_vm._e(),(
                          _vm.carDoors !== 0 &&
                          _vm.carDoors !== null &&
                          !_vm.toRemove.includes('doors')
                        )?_c('li',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Portas")]),_c('div',[_vm._v(_vm._s(_vm.carDoors))])]):_vm._e(),(
                          _vm.carSeats !== 0 &&
                          _vm.carSeats !== null &&
                          !_vm.toRemove.includes('seats')
                        )?_c('li',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Lugares")]),_c('div',[_vm._v(_vm._s(_vm.carSeats))])]):_vm._e(),(
                          _vm.carPlate !== null && !_vm.toRemove.includes('plate')
                        )?_c('li',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Matrícula")]),_c('div',[_vm._v(_vm._s(_vm.carPlate))])]):_vm._e(),(
                          _vm.carPlate !== null && !_vm.toRemove.includes('warranty')
                        )?_c('li',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Garantia")]),_c('div',{staticStyle:{"font-size":"0.9rem","text-align":"right"},domProps:{"innerHTML":_vm._s(_vm.warranty)}})]):_vm._e(),(
                          _vm.carPlate !== null && !_vm.toRemove.includes('warranty')
                        )?_c('li',{staticClass:"d-flex justify-content-between"},[(parseInt(_vm.warranty) < 36)?_c('div',{staticStyle:{"font-weight":"400","text-transform":"none","font-size":"0.7rem"}},[_vm._v(" Duração da garantia, por acordo, nos termos do nº 3 do art.º 12.º do DL nº 84/2021, de 18 de outubro. ")]):_vm._e()]):_vm._e()]):_vm._e()])])])])])])])]),_c('div',{staticClass:"row d-md-none fixed-bottom sticky-cta text-center"},[_vm._m(2),_vm._m(3),_c('div',{staticClass:"col-4 px-1"},[_c('a',{attrs:{"id":"whats","href":_vm.url,"target":"_blank","rel":"noopener"},on:{"click":_vm.triggerWhatsapp}},[_c('i',{staticClass:"fab fa-whatsapp"}),_c('br'),_vm._v(" Contactar ")])])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-6 col-lg-2 d-md-block d-none"},[_c('div',{staticClass:"text-center text-lg-left"},[_c('a',{attrs:{"href":"#form"}},[_c('button',{staticClass:"btn btn-secondary btn-block btn-extra"},[_c('i',{staticClass:"fas fa-file-alt"}),_vm._v(" Pedir Proposta ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-6 col-lg-2 pl-0 d-md-block d-none"},[_c('div',{staticClass:"text-center text-lg-left"},[_c('a',{attrs:{"href":"#form"}},[_c('button',{staticClass:"btn btn-secondary btn-block btn-extra"},[_c('span',{staticClass:"icon-test-drive"}),_vm._v(" Marcar Test Drive ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 px-1"},[_c('a',{attrs:{"href":"#form"}},[_c('i',{staticClass:"fas fa-file-alt"}),_c('br'),_vm._v(" Pedir Proposta ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 px-1"},[_c('a',{attrs:{"href":"#form"}},[_c('span',{staticClass:"icon-test-drive sticky-icon"}),_c('br'),_vm._v(" Marcar Test Drive ")])])
}]

export { render, staticRenderFns }