<template>
	<div class="container-fluid catalog-container-v1" v-if="isLoaded">
		<div class="container"
			 v-if="config.catalog.back_link_active === 1 && (config.catalog.back_link_position === 'top'|| config.catalog.back_link_position === 'mixed')">
			<div class="row">
				<div class="col-12 pt-3 pb-2">
					<a class="back-link" :href="setUrlQuery()" rel="noopener"><i
						class="fas fa-arrow-left pr-2"></i>
						<span v-html="config.catalog.back_link_text"></span></a>
				</div>
			</div>
		</div>
		<div class="container position catalog mb-3">
			<div class="row">
				<div class="col-12 left-side"
					 :class="{'col-lg-8' : formData !== null && !formData.fat_form, 'col-md-12 col-lg-6' : formData !== null && formData.fat_form}">
					<div class="row">
						<div class="col-12 pb-4">
							<a :href="setUrlQuery()" target="_blank" rel="noopener">
								<img
									:src="config.bg_desktop !== null ? setImgFallback(config.bg_desktop) : setImgFallback(config.campaign_desktop_image)"
									alt=""
									class="img-fluid d-none d-md-block">
								<img
									:src="config.bg_mobile !== null ? setImgFallback(config.bg_mobile) : setImgFallback(config.campaign_mobile_image)"
									alt=""
									class="img-fluid d-block d-md-none">
							</a>
						</div>
					</div>
					<div class="row pb-3">
						<div class="col-12">
							<div class="d-md-flex justify-content-md-between">
								<div class="d-md-flex align-content-md-start">
									<div>
										<h1>{{ carMake }} {{ carModel }}</h1>
										<h2>{{ carVersion }}</h2>
									</div>
								</div>
								<div class="d-md-flex align-content-md-end mt-4 mb-2 mt-md-0 mb-md-0"
									 v-if="isPromoPrice === 1 && config.catalog.campaign_is_car_promo === 1">
									<div class="align-self-md-center text-md-right">
										<p class="price-old" v-if="carOldPrice !== 0">
											{{
												carOldPrice | currency('€', 0, {
													thousandsSeparator : '.', symbolOnLeft : false
												})
											}}
										</p>
										<p class="price" v-if="carPrice !== 0">
											{{
												carPrice | currency('€', 0, {
													thousandsSeparator : '.', symbolOnLeft :
														false
												})
											}}
										</p>
										<div class="price" v-if="carPrice === 0"
											 style="font-size: 1.3rem;line-height: 1.2rem;">
											Sob Consulta
										</div>
									</div>
								</div>
								<div class="d-md-flex align-content-md-end mt-4 mb-2 mt-md-0 mb-md-0"
									 v-if="(isPromoPrice !== 1 && config.catalog.campaign_is_car_promo === 1) || config.catalog.campaign_is_car_promo !== 1">
									<div class="align-self-md-center text-md-right">
										<p class="price" v-if="carOldPrice !== 0">
											{{
												carOldPrice | currency('€', 0, {
													thousandsSeparator : '.', symbolOnLeft : false
												})
											}}
										</p>
										<div class="price" v-if="carPrice === 0"
											 style="font-size: 1.3rem;line-height: 1.2rem;">
											Sob Consulta
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row pb-4">
						<div class="col-12">
							<b-carousel id="carousel1"
										v-model="slide"
										style="text-shadow: 1px 1px 2px #333;"
										controls
										background="#efefef"
										:interval="4000"
										img-width="1024"
										img-height="480"
										@sliding-start="onSlideStart"
										@sliding-end="onSlideEnd">
								<b-carousel-slide v-for="image of sliderImages" v-bind:key="image">
									<img slot="img" class="d-block img-fluid w-100"
										 width="1024"
										 height="480"
										 v-lazy="setListingImg(image)" alt="image slot">
								</b-carousel-slide>
							</b-carousel>
						</div>
					</div>

					<div class="row pb-4 pb-md-0">
						<div class="col-12">
							<div class="card">
								<div class="card-body">
									<div class="row stats">
										<div class="col-6 col-md-4">
											<img src="@/assets/images/ic_calendario.svg"
												 alt=""><span
											style="text-transform: capitalize">{{ carRegistrationDate }}</span>
										</div>
										<div class="col-6 col-md-4">
											<img src="@/assets/images/ic_combustivel.svg"
												 alt="">{{ carFuelType }}
										</div>
										<div class="col-6 col-md-4">
											<img src="@/assets/images/ic_cilindrada.svg"
												 alt="">{{ carEngineSize }}
										</div>
										<div class="col-6 col-md-4">
											<img src="@/assets/images/ic_viatura.svg"
												 class="cars"
												 alt="">{{ carType }}
										</div>
										<div class="col-6 col-md-4">
											<img src="@/assets/images/ic_quilometros.svg"
												 alt="">{{ carCurrentKms }}
										</div>
										<div class="col-6 col-md-4">
											<img src="@/assets/images/ic_Potencia.svg"
												 alt="">{{ carHorsePower }}
										</div>

										<div class="col-12">
											<i class="far fa-check-circle pr-1"
											   style="color: rgba(0,0,0,0.15);"></i> <span class="pr-2"
																						   style="color: rgba(0,0,0,0.5); font-size: 0.9rem;">Garantia</span>
											<span style="font-size: 0.9rem" v-html="warranty"></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 right-side" id="form"
					 v-if="formData !== null"
					 :class="{'col-md-12 col-lg-4' : !formData.fat_form, 'col-md-12 col-lg-6' : formData.fat_form}"
					 v-observe-visibility="visibilityChanged">
					<contact-form :config="config" :form-data="formData" :setup="setup"
								  :json-config="jsonConfig"></contact-form>
				</div>

				<div class="container"
					 v-if="config.catalog.back_link_active === 1 && (config.catalog.back_link_position === 'bottom'|| config.catalog.back_link_position === 'mixed')">
					<div class="row">
						<div class="col-12 mt-5 mb-4">
							<div class="d-flex justify-content-center">
								<a class="back-link" :href="setUrlQuery()" rel="noopener">
									<span v-html="config.catalog.back_link_text"></span><i
									class="fas fa-arrow-right pl-2"></i></a>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 mt-3 mt-md-3 disclaimer mb-3 mb-md-3"
					 v-if="setup.disclaimer_zone === 'top'">
					<p id="privacy" v-html="privacyText"></p>
					<p id="contract" v-html="campaignContract">
					</p>
					<p id="disclaimer" v-html="setup.campaign_disclaimer"></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import gtmEventCarDetailMixin      from '@/common/gtm-event-car-detail-mixin';
import pricesMixin                 from '@/common/prices-mixin';
import setImgFallbackMixin         from '@/common/set-img-fallback-mixin';
import ContactForm                 from '@/components/shared/ContactForm';
import {BCarousel, BCarouselSlide} from 'bootstrap-vue';
import * as moment                 from 'moment';

moment.locale('pt');

export default {
	name       : 'CatalogContainerV1',
	components : {
		ContactForm,
		BCarousel,
		BCarouselSlide
	},
	mixins     : [gtmEventCarDetailMixin, pricesMixin, setImgFallbackMixin],
	props      : {
		config           : {
			type     : Object,
			required : true,
		},
		formData         : {
			type     : Object,
			required : true,
		},
		setup            : {
			type     : Object,
			required : true,
		},
		privacyText      : {
			type     : String,
			required : true,
		},
		campaignContract : {
			type     : String,
			required : true,
		}
	},
	data() {
		return {
			response            : Object,
			isLoaded            : false,
			jsonConfig          : this.config.json !== null ? this.config.json.json_config : {},
			slide               : 0,
			sliding             : null,
			listingId           : String,
			carPrice            : String,
			carOldPrice         : String,
			carMake             : String,
			carModel            : String,
			carVersion          : String,
			carEngineSize       : String,
			carRegistrationDate : String,
			carCurrentKms       : String,
			carHorsePower       : String,
			carFuelType         : String,
			carType             : String,
			warranty            : String,
			sellerName          : String,
			sellerLocation      : String,
			sellerId            : Number,
			sellerAddress       : String,
			sellerCp            : String,
			sellerLogo          : String,
			sliderImages        : Array,
			uuid                : String,
			title               : String,
			description         : String,
			isPromoPrice        : Boolean,
		};
	},
	metaInfo() {
		return {
			title : this.title,
			meta  : [
				{
					name    : 'description',
					content : this.description,
				}
			]
		};
	},
	async mounted() {
		try {
			if (this.$route.params.listing_id !== undefined) {
				await this.$store.dispatch('getListingResponseData', {
					sellers   : [this.config.setup.seller_id.toString()].concat(this.config.catalog.seller_cars_merge !== null ? JSON.parse(this.config.catalog.seller_cars_merge) : []),
					listingId : this.$route.params.listing_id,
					catalogId : this.config.ad_engine_lps_catalogs_id
				});

				this.response    = this.$store.state.listingData.data;
				this.sellerName  = this.response.Seller.seller_has_seller_company[0]['seller_company'][0]['PublicName'] !== null ? this.response.Seller.seller_has_seller_company[0]['seller_company'][0]['PublicName'] : this.response.Seller.seller_has_seller_company[0]['seller_company'][0]['CompanyName'];
				this.title       = `${this.response.CarNexus.car_makes.MakeName === 'Skoda' ? 'ŠKODA' : this.response.CarNexus.car_makes.MakeName} ${this.response.CarNexus.ModelName} de ${moment(this.response.CarMainData.RegistrationDate, 'YYYY-MM-DD hh:mm:ss').format('YYYY')} - ${this.sellerName}`;
				this.description = `Descubra na ${this.sellerName} as Oportunidades em carros Usados e Seminovos ${this.response.CarNexus.car_makes.MakeName === 'Skoda' ? 'ŠKODA' : this.response.CarNexus.car_makes.MakeName} ${this.response.CarNexus.ModelName}.`;

				await this.$store.dispatch('getListingId', this.response.listing_id);
				await this.$store.dispatch('getListingLocationId', this.response.all_address_id);

				this.isLoaded = true;
				this.setData(this.response);
				this.$eventBus.$on('set-gtm', (data) => {
					if (data) {
						this.$gtm.trackEvent(this.trigger(this.response, this.$store.state.alid));
					}
				});
				window.alp.trackEvent({
					'event'          : 'ViewContent',
					'collected_data' : this.trigger(this.response, this.$store.state.alid)
				});

				return;
			}

			window.location = window.location.href.split('viaturas')[0];
		} catch (error) {
			if (error.response.status === 500) {
				window.location = window.location.href.split('viaturas')[0];
			} else {
				window.location = error.response.data.message.redirect_url;
			}
		}
	},
	methods : {
		setWarranty(data) {
			let warranty = '';
			if (data.WarrantySellerTime !== null) {
				warranty += data.WarrantySellerTime + ' meses, ';
			}
			if (data.WarrantySellerKms !== null) {
				warranty += data.WarrantySellerKms + ' Kms, ';
			}
			if (data.WarrantySellerDescription !== null) {
				warranty += data.WarrantySellerDescription + ', ';
			}
			if (data.WarrantyManufTime !== null) {
				warranty += data.WarrantyManufTime + ' meses, ';
			}
			if (data.WarrantyManufKms !== null) {
				warranty += data.WarrantyManufKms + ' Kms, ';
			}
			if (data.WarrantyManuDescription !== null) {
				warranty += data.WarrantyManuDescription + ', ';
			}

			if (warranty !== '') {
				return warranty.replace(/,(?=[^,]*$)/, '.');
			}
			return 'Sob Consulta';
		},
		setData(data) {
			let list                 = data;
			this.listingId           = list.listing_id;
			this.carMake             = list.CarNexus.car_makes.MakeName === 'Skoda' ? 'ŠKODA' : list.CarNexus.car_makes.MakeName;
			this.carModel            = list.CarNexus.ModelName;
			this.carVersion          = list.CarMainData.Version;
			this.carEngineSize       = `${list.CarMainData.EngineSize === -1 ? 0 : list.CarMainData.EngineSize} cm3`;
			this.carRegistrationDate = moment(list.CarMainData.RegistrationDate, 'YYYY-MM-DD hh:mm:ss').format('MMM YYYY');
			this.carCurrentKms       = `${list.CarMainData.CurrentKms} Km`;
			this.carHorsePower       = `${list.CarMainData.HorsePower} cv`;
			this.carFuelType         = list.CarMainData.fuel_type.FuelType;
			this.carType             = list.CarMainData.car_type.ModelType;
			this.sellerLocation      = list.Address.Locality;
			this.sellerId            = list.seller_id;
			this.sellerAddress       = list.Address.Address;
			this.sellerCp            = `${list.Address.CP4}-${list.Address.CP3}`;
			this.uuid                = list.uuid;
			this.sliderImages        = list.ItemImages.front !== undefined ? list.ItemImages.main.concat(list.ItemImages.front) : list.ItemImages.main;
			this.options             = {
				make  : this.carMake,
				model : this.carModel,
				price : this.carPrice,
				year  : this.carRegistrationDate,
			};
			this.warranty            = this.setWarranty(list.CarMainData);

			let prices        = this.setPrices(this.config, list);
			this.isPromoPrice = prices.isPromoPrice;
			this.carPrice     = prices.carPrice;
			this.carOldPrice  = prices.carOldPrice;

			this.setLocation({
				StandName : list.Address.seller_stand.VisibleName !== null ? list.Address.seller_stand.VisibleName : list.Address.seller_stand.StandName,
				Address   : list.Address.Address,
				CP4       : list.Address.CP4,
				CP3       : list.Address.CP3,
				Locality  : list.Address.Locality,
				schedules : list.Address.seller_stand.schedules,
				PhoneOne  : list.Address.seller_stand.PhoneOne,
			});
		},
		setLocation(obj) {
			this.$eventBus.$emit('set-location', obj);
		},
		setUrlQuery() {
			let routeQuery = this.$route.query;
			let query      = {};

			for (let [k, v] of Object.entries(routeQuery)) {
				if (k !== 'debug' && k !== 'preview' && k !== 'teste') {
					query[k] = v;
				}
			}

			if (Object.entries(query).length > 0) {
				let serializeObj = Object.entries(query).map(([key, val]) => `${key}=${val}`).join('&');
				return `${this.$store.state.currentUrl}?${serializeObj}`;
			}

			return this.$store.state.currentUrl;
		},
		visibilityChanged(isVisible, entry) {
			this.isVisible = isVisible;
		},
		onSlideStart(slide) {
			this.sliding = true;
		},
		onSlideEnd(slide) {
			this.sliding = false;
		},
		setListingImg(image) {
			if (process.env.NODE_ENV === 'development') {
				return 'https://www.rotauto.com/assets/img/imagemOG_rotauto2020.jpg';
			}
			return `${process.env.VUE_APP_ROTAUTO_IMG_SOURCE}/${this.uuid}/${image}`;
		}
	}
};
</script>
