const pricesMixin = {
	methods : {
		setPrices(config, list) {
			if (config.catalog.catalog_listings_price.length > 0) {
				let plate = config.catalog.catalog_listings_price.filter((e) => {
					return list.CarMainData.RegistrationPlate === e.plate;
				});

				if (plate.length > 0) {
					return {
						isPromoPrice : plate[0].price_after !== null ? 1 : 0,
						carPrice     : plate[0].price_after !== null ? plate[0].price_after : list.SalePrice,
						carOldPrice  : plate[0].price_before !== null ? plate[0].price_before : list.SalePrice,
					};
				}
			}

			return {
				isPromoPrice : list.IsPromoPrice,
				carPrice     : list.IsPromoPrice === 1 && list.PromoPrice !== null ? (config.catalog.campaign_is_car_promo === 1 ? list.PromoPrice : list.SalePrice) : list.SalePrice,
				carOldPrice  : list.SalePrice,
			};
		},
	}
};

export default pricesMixin;
